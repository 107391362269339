"use client";

import { handleLogin } from "@/components/Form/LoginForm/_actions/login";
import { handleLogout } from "@/components/Form/LogoutForm/_actions/logout";
import useStoreKeys from "@/hooks/useStoreKeys";
import { getTranslatedPath } from "@/i18n/urls/urls";
import { SessionData } from "@/lib/auth/session";
import { useRouter, useSearchParams } from "next/navigation";
import { createContext, useContext, useEffect, useState } from "react";
import { Cart } from "../5874/types";
import { handleJwtLogin } from "@/app/ecom/[store]/[lang]/login/token/[jwt]/_components/_actions/login";
import { getAccountNumber } from "./auth-utils";

export const AUTH_API_ROUTE = "/api/auth";

interface State {
	isLoading: boolean;
	session: SessionData | null;
	login: (
		email: string,
		password: string,
		rememberMe: boolean,
	) => Promise<{ cart: Cart | undefined; url: string } | undefined>;
	loginJwt: (
		jwt: string,
	) => Promise<{ cart: Cart | undefined; url: string } | undefined>;
	logout: (skipRedirect?: boolean) => void;
}

interface ProviderProps {
	session: SessionData | null;
	children?: React.ReactNode;
}

export const SessionContext = createContext({} as State);

export const SessionContextProvider = ({
	session: initSession,
	children,
}: ProviderProps) => {
	const router = useRouter();
	const { store, lang } = useStoreKeys();
	const searchParams = useSearchParams();

	const [session, setSession] = useState<SessionData | null>(initSession);
	// console.debug("🚀 ~ session:", session);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await getAccountNumber();
				if (data) {
					sessionStorage.setItem("accountNumber", data);
				}
			} catch (error) {
				console.error("Failed to fetch accountNumber");
			}
		};

		fetchData();

		return () => sessionStorage.setItem("accountNumber", "");
	}, []);

	useEffect(() => {
		if (session?.store && session.store !== store) {
			logout(true);
		}
	}, [session, store]);

	const login = async (
		email: string,
		password: string,
		rememberMe: boolean,
	) => {
		setIsLoading(true);

		const results = await handleLogin({ email, password, rememberMe }, store);
		//TODO - Error handling logic
		if (results?.status !== "success") {
			setIsLoading(false);
			return;
		}

		if (results.data) {
			setSession(results.data.session);
			setIsLoading(false);
			const redirect = searchParams.get("redirect");

			let url: string;

			if (redirect) {
				url = redirect;
			} else {
				url = getTranslatedPath({
					language: lang,
					url: "/account",
					prefix: `/${store}/${lang}`,
				});
			}
			return {
				cart: results.data.cart,
				url,
			};
		}

		//TODO - This needs to return result so error can be handled on the UI
		setIsLoading(false);
	};

	const loginJwt = async (jwt: string) => {
		setIsLoading(true);

		const results = await handleJwtLogin(jwt, store);

		let url: string;

		if (results.status !== "success") {
			setIsLoading(false);

			url = getTranslatedPath({
				language: lang,
				url: "/login",
				prefix: `/${store}/${lang}`,
			});

			router.push(`${url}?error=invalidToken`);

			return;
		}

		if (results.data) {
			setSession(results.data.session || null);
			setIsLoading(false);
			const redirect = searchParams.get("redirect");

			if (redirect) {
				url = redirect;
			} else {
				url = getTranslatedPath({
					language: lang,
					url: "/account",
					prefix: `/${store}/${lang}`,
				});
			}
			return {
				cart: results.data.cart,
				url,
			};
		}

		//TODO - This needs to return result so error can be handled on the UI
		setIsLoading(false);
	};

	const logout = async (skipRedirect?: boolean) => {
		const result = await handleLogout();

		if (result.status !== "success") {
			setIsLoading(false);
			return result;
		}

		setSession(null);

		if (!skipRedirect) {
			router.push(
				getTranslatedPath({
					language: lang,
					url: "/login",
					prefix: `/${store}/${lang}`,
				}),
			);
		}
	};

	const value = {
		isLoading,
		session,
		login,
		logout,
		loginJwt,
	};

	return (
		<SessionContext.Provider value={value}>{children}</SessionContext.Provider>
	);
};

export const useSessionContext = () => {
	const context = useContext(SessionContext);

	if (!context) {
		throw new Error(
			"useSessionContext must be used within a SessionContextProvider",
		);
	}

	return context;
};
